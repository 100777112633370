//@flow
import { execute, validate, type ExecutionResult } from 'graphql';
import { makeExecutableSchema, addMockFunctionsToSchema } from 'graphql-tools';
import { Observable } from '@apollo/client';
import type { ApolloLink, Operation, NextLink, FetchResult } from '@apollo/client';

import type { LinkSchemaRestOptions } from './types';
import buildResolver from './buildResolver';

export { ApolloLinkSchemaRestError } from './resolvers';
export { default as useQuery } from './useQuery';
export { default as useLazyQuery } from './useLazyQuery';

export type { NewFetchMore } from './makeFetchMore';

/**
 * RestLink is an apollo-link for communicating with REST services using GraphQL on the client-side
 */
export default function linkSchemaRest(options: LinkSchemaRestOptions): ApolloLink {
  const { schema, mockResolvers } = options;

  const definitions = schema.map(schema => schema.definitions);

  const resolver = definitions.map(definition => buildResolver(definition, options));

  const executableSchema = makeExecutableSchema({
    typeDefs: schema,
    resolvers: resolver,
    resolverValidationOptions: {
      requireResolversForAllFields: true,
    },
  });

  // $FlowFixMe
  return {
    request(
      operation: Operation,
      forward?: NextLink,
    ): Observable<FetchResult<$PropertyType<ExecutionResult, 'data'>, void>> {
      const { query, variables } = operation;

      if (mockResolvers) {
        addMockFunctionsToSchema({
          schema: executableSchema,
          mocks: mockResolvers,
          preserveResolvers: false,
        });
      }

      if (forward) {
        throw new Error(
          `@dt/apollo-link-schema-rest must be the last link in the chain and cannot forward anything at the moment`,
        );
      }

      const validationErrors = validate(executableSchema, query);
      if (validationErrors.length > 0) {
        // $FlowFixMe - Readonly errors vs validationErrors
        return Observable.of({ errors: validationErrors });
      }

      return new Observable(observer => {
        Promise.resolve(
          execute({
            schema: executableSchema,
            document: query,
            contextValue: { rest: { db: {} } },
            variableValues: variables,
          }),
        )
          .then(result => {
            observer.next({ data: result.data, errors: result.errors });
            observer.complete();
          })
          .catch(err => {
            if (err.name === 'AbortError') return;
            if (err.result && err.result.errors) {
              observer.next(err.result);
            }
            observer.error(err);
          });
      });
    },
  };
}
