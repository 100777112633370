//@flow
import type { ResolverMap } from 'graphql-tools';

const DefaultResolvers: ResolverMap = {
  PaginationInformation: () => ({
    next_cursor: () => undefined,
  }),
  Date: () => new Date(),
};
const getMockResolvers = (mocks: ?ResolverMap) =>
  process.env.NODE_ENV === 'test' && !Array.isArray(mocks) && !Array.isArray(DefaultResolvers)
    ? {
        ...DefaultResolvers,
        // $FlowFixMe
        ...mocks,
      }
    : null;

export default getMockResolvers;
